import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Loader from './components/loader';
import { Header } from './components/header';
import Footer from './components/footer';
import Error from './components/404';
const Home = lazy(() => import('./pages/home'));

const Vipsportsbookip = lazy(() => import('./pages/vipsportsbook.js'));
const Betgigante = lazy(() => import('./pages/betgigante.js'));
const WhyUs = lazy(() => import('./pages/whyUs.js'));
const Commisions = lazy(() => import('./pages/commisions'));
const Faqs = lazy(() => import('./pages/faqs'));
const Contact = lazy(() => import('./pages/contact_us'));
const Payment_Method = lazy(() => import('./pages/payment_method'));

//const Error =lazy (()=>import('./components/404'));
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>        
        <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact index path="/home" element={<Home />} />
            <Route exact path="/vipsportsbook" element={<Vipsportsbookip />} />
            <Route exact path="/betgigante" element={<Betgigante />} />
            <Route exact path="/why_us" element={<WhyUs />} />
            <Route exact path="/commissions" element={<Commisions />} />
            <Route exact path="/faqs" element={<Faqs />} />
            <Route exact path="/contact_us" element={<Contact />} />
            <Route exact path="/payment_method" element={<Payment_Method />} />


            <Route path="*" element={<Error/>}>
            </Route>
          </Routes>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;

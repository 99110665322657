import React from 'react';
import dieciocho from '../assets/images/responsible.webp';
import logo from '../assets/images/general/logo-small.png';
export default function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <div className="bg_black text-center text-white py-3">
      <div className="row g-0">
        <div className="col-lg-2">
          <a href="/">
            <img src={logo} alt="Logo Prominent Gaming"></img>
          </a>
          {/* <div className="row g-0 fs-3">
            <div className="col">
              <a className="links" href="https://www.facebook.com/profile.php?id=61550319072901" target="_blank">
                <i className="fa-brands fa-facebook"></i>
              </a>
            </div>
            <div className="col">
              <a className="links" href="https://www.instagram.com/global_gaming_af/" target="_blank">
              <i className="fa-brands fa-instagram"></i>
              </a>
            </div>
            <div className="col">
              <a className="links" href="https://twitter.com/GlobalGamingAF" target="_blank">
              <i className="fa-brands fa-x-twitter"></i>
              </a>
            </div>
          </div> */}
        </div>
        <div className="col-lg-7 d-flex justify-content-center align-items-center py-5">
          <div className="row g-0 w-100">
            <div className="col-lg-2">
              <ul className="menu text-center">
                <li className="w-100">
                  <a className="nav-link">
                    Brands
                  </a>
                  <ul className="submenu">
                    <li><a href="/vipsportsbook">VIP Sportsbook</a></li>
                    <li><a href="/betgigante">Bet Gigante</a></li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-lg-2"><a className="nav-link text-white" href="/commissions">Commissions</a></div>
            <div className="col-lg-2"><a className="nav-link text-white " href="/payment_method">Payment Method</a></div>
            <div className="col-lg-2"><a className="nav-link text-white " href="/faqs">FAQ</a></div>
            <div className="col-lg-2"><a className="nav-link text-white " href="/contact_us">Contact Us</a></div>
          </div>
        </div>
        <div className="col-lg-3 d-flex justify-content-center align-items-center">
          <img src={dieciocho} alt="responsible gambling"></img>
        </div>


      </div>
      <div className="col-12 py-3">
        Copyright © Prominent Gaming {year}. All rights reserved.
      </div>

    </div>
  )
}
